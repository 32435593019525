@import '../../scss/theme-bootstrap';

.sd-grid-formatter {
  .breadcrumbs {
    background: transparent;
    max-width: 1440px;
    margin: 0 auto;
  }
  .breadcrumbs__level {
    padding-#{$ldirection}: 2px;
    a {
      border-bottom: none;
      pointer-events: none;
      &[href]:not([href='']) {
        pointer-events: inherit;
      }
    }
    &:first-child {
      padding-#{$ldirection}: 0;
      &:before {
        content: '';
        display: none;
      }
    }
    &:before {
      content: '';
      background: url('#{$base-theme-path}svg-icons/src/icon--chevron-up.svg');
      transform: rotate(90deg);
      width: 10px;
      height: 9px;
      background-size: cover;
      background-repeat: no-repeat;
      display: inline-block;
      [dir='rtl'] & {
        background: url('#{$base-theme-path}svg-icons/src/icon--chevron-down.svg');
      }
    }
  }
}
.elc-search-results-wrapper,
.elc-search-enhanced-grid-wrapper,
.sd-product-grid {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  .elc-grid-item-product {
    position: relative;
    // remove condition in ASMBLY3-1246
    @if $cr21-images == false {
      &:nth-of-type(3n + 1) {
        &:before {
          @include breakpoint($bp--medium-up) {
            content: '';
            width: 300%;
            position: absolute;
            z-index: 2;
            height: 1px;
            border-top: 1px solid $color-cta-grey;
            top: -13px;
            #{$ldirection}: 0;
          }
        }
      }
      &:nth-of-type(1) {
        &:before {
          @include breakpoint($bp--medium-up) {
            content: unset;
          }
        }
      }
    }
  }
  .elc-grid-item-tout {
    .elc-basic-tout__link {
      padding: 0 0 12px;
    }
  }
  .elc-product-tout-wrapper {
    .elc-spinner-container {
      aspect-ratio: 9/16;
    }
  }
  .elc-grid-container {
    margin: 0;
  }
  .elc-sticky-container {
    z-index: 3;
  }
  .elc-product-grid--header {
    flex-direction: column;
    padding: 0;
  }
  .elc-product-grid-wrapper {
    margin: 0 0 20px;
  }
  .elc-product-brief-view {
    width: calc(100% + 16px);
    margin-left: -8px;
    .filters-applied & {
      margin-top: 10px;
    }
  }
  .elc-sort-wrapper {
    align-self: flex-end;
    label {
      display: flex;
      &:after {
        content: '';
        background: url('#{$base-theme-path}svg-icons/src/icon--chevron-down.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 15px;
        height: 12px;
        position: absolute;
        #{$rdirection}: 10px;
        top: 17px;
      }
    }
    width: auto;
    select {
      @include body-text--small-bold;
      text-decoration: none;
      color: $color--black;
      height: 45px;
      background: unset;
      border: 1px solid $color-cta-grey;
      border-radius: 100px;
    }
  }
  .elc-size-picker-container {
    z-index: 11;
    margin-block: 8px 20px;
  }
  .elc-dropdown {
    border-radius: 100px;
    &.elc-size-picker-dropdown {
      background: $color--white;
    }
    &-wrapper {
      border: 1px solid $color-cta-grey;
      border-radius: 100px;
      @include breakpoint($bp--large-up) {
        min-width: 200px;
      }
      .elc-dropdown-wrapper,
      .elc-dropdown-input-wrapper {
        border: none;
      }
      .elc-dropdown-arrow-icon {
        -webkit-mask-image: url('#{$base-theme-path}svg-icons/src/icon--chevron-down.svg');
        mask-image: url('#{$base-theme-path}svg-icons/src/icon--chevron-down.svg');
        width: 15px;
        height: 15px;
      }
    }
    &-readonly-input {
      @include body-text--small-bold;
      text-transform: uppercase;
      padding-#{$rdirection}: 0;
    }
    &-arrow {
      .sd-grid-formatter & {
        border: none;
      }
    }
    &-options-list {
      border: 1px solid $color-cta-grey;
      border-radius: 20px;
      margin-top: 5px;
      #{$ldirection}: unset;
      min-width: calc(100% + 10px);
    }
    &-options {
      z-index: 10;
    }
    &-option {
      @include body-text;
      position: relative;
      @include swap_direction(padding, 10px 10px 10px 25px);
      white-space: nowrap;
      &:before {
        // this should include something ( basic radio style )
        content: ' ';
        width: 9px;
        height: 9px;
        border: 1px solid $color--black;
        border-radius: 5px;
        display: flex;
        position: absolute;
        top: 17px;
        #{$ldirection}: 7px;
      }
      &.active {
        &:after {
          content: ' ';
          width: 5px;
          height: 5px;
          background: $color--black;
          border-radius: 3px;
          display: flex;
          position: absolute;
          top: 20px;
          #{$ldirection}: 10px;
        }
      }
    }
    &.elc-product-sorting-dropdown {
      .elc-dropdown-option {
        &:first-child {
          display: none;
        }
      }
    }
  }
  .elc-product-cta-wrapper {
    .elc-custom-cta,
    .elc-product-cta {
      width: auto;
    }
  }
}
.sd-grid-formatter {
  .basic-responsive-v1 {
    p {
      @include body-text;
      font-size: 15px;
    }
  }
}

.sd-transition {
  padding-inline: 15px;
}
.sd-grid-skeleton {
  padding: 0;
  .skeleton-cta {
    cursor: progress;
  }
  .sd-grid-skeleton-filters {
    padding: 0 8px;
    .skeleton-bar {
      border-radius: 100px;
    }
  }
  .skeleton-shades-grid {
    display: flex;
    justify-content: center;
  }
  .skeleton-shade {
    width: 30px;
    margin-inline: 4px;
    height: 30px;
    border-radius: 50%;
    @include breakpoint($bp--large-up) {
      margin-inline: 0;
    }
  }
  .skeleton-bar-slider--arrows {
    display: none;
    &.is-slider {
      align-self: end;
      height: 48px;
      @include breakpoint($bp--large-up) {
        display: flex;
      }
    }
  }
  .skeleton-bar-slider--dots {
    display: none;
    &.is-slider {
      display: flex;
      align-self: center;
      height: 18px;
      margin-block-end: 20px;
      @include breakpoint($bp--large-up) {
        display: none;
      }
    }
  }
  .elc-great-to-know-container {
    width: 100%;
  }
  .elc-product-prices-clickable-wrapper {
    width: 100px;
  }
  .sd-grid-skeleton-items {
    margin-top: 0;
    .skeleton-item {
      width: auto;
      height: auto;
      margin: 8px;
    }
    &.is-slider {
      .skeleton-item {
        margin-inline: 0 4px;
        &.elc-grid-item-product.elc-product-brief {
          display: flex;
        }
        @include breakpoint($bp--large-up) {
          margin-inline: 4px;
        }
        &.elc-grid-item-tout {
          margin-inline-end: 20px;
          aspect-ratio: 9/16;
          @include breakpoint($bp--large-up) {
            margin-inline-end: 4px;
          }
        }
      }
    }
  }
}
